import {
	faBuildingColumns,
	faCashRegister,
	faCheck,
	faCommentCheck,
	faCommentDots,
	faCompassDrafting,
	faDiploma,
	faEnvelopeCircleCheck,
	faEnvelopeOpenText,
	faEnvelopesBulk,
	faFileCertificate,
	faFileCheck,
	faFileUser,
	faHandHoldingDollar,
	faHandsHoldingDollar,
	faHourglassClock,
	faLaptopSlash,
	faLinkSlash,
	faMobileSignal,
	faMoneyCheckDollarPen,
	faNewspaper,
	faPaperPlane,
	faPersonToDoor,
	faPlay,
	faSlash,
	faTimer,
	faTimes,
	faTrash,
} from "@fortawesome/pro-regular-svg-icons"
import {
	faUserMinus as iconRecessoSocio,
	faMessageDollar as iconCommMorosi,
	faPersonToDoor as iconEspulsione,
} from "@fortawesome/pro-solid-svg-icons"

export const commonActions = {
	'_created': {
		label: 'Procedura avviata',
		icon: faPlay,
	},
	'_aborted': {
		label: 'Procedura annullata',
		icon: faTrash,
	},
}

export const workflowsDef = {

	'recesso-socio': {
		label: 'Recesso socio',
		icon: iconRecessoSocio,
		actions: {
			'eval-fiscale': {
				label: 'Calcolo quote fiscale',
				icon: faBuildingColumns,
			},
			'eval-paghe': {
				label: 'Calcolo quote paghe',
				icon: faHandHoldingDollar,
			},
			'eval-caa': {
				label: 'Calcolo quote CAA',
				icon: faCompassDrafting,
			},

			'close-ago-fiscale': {
				label: 'Chiusura AGO - Fiscale',
				icon: faLaptopSlash,
			},
			'close-ago-paghe': {
				label: 'Chiusura AGO - Paghe',
				icon: faLaptopSlash,
			},
			'close-ago-amministrazione': {
				label: 'Chiusura AGO - Amministrazione',
				icon: faLaptopSlash,
			},

			'close-telematici': {
				label: 'Disattivazione F24 telematici',
				icon: faMobileSignal,
			},
			'close-tic': {
				label: 'Chiusura TIC',
				icon: faLinkSlash,
			},
			'close-newsletter': {
				label: 'Rimozione dalla newsletter',
				icon: faEnvelopesBulk,
			},
			'close-newspaper': {
				label: 'Rimozione dal giornale',
				icon: faNewspaper,
			},

			'create-comm': { icon:faEnvelopeOpenText, label:'Prepara lettera per il cliente', customAction:'recesso-socio-comm' },
			'send-comm': { icon:faPaperPlane, label:'Invio comunicazione al cliente' },
			'skip-comm': { icon:faPaperPlane, icon2:faSlash, label:'Salta comunicazione al cliente' },

			'docs-prepared': { icon:faFileCheck, label:'Documenti preparati' },
			'docs-to-hq': { icon: faFileCertificate, label:'Documenti depositati in sede' },
			'docs-delivered': { icon:faFileUser, label:'Documenti consegnati' },
		},

		states: [
			{ tag:'quoteFiscaleEvaluated', icon:faBuildingColumns, label:'Calcolo quote fiscale' },
			{ tag:'quotePagheEvaluated', icon:faHandHoldingDollar, label:'Calcolo quote paghe' },
			{ tag:'quoteCAAEvaluated', icon:faCompassDrafting, label:'Calcolo quote CAA' },
		
			{ tag:'agoFiscaleClosed', icon:faLaptopSlash, label:'Chiusura AGO - Fiscale' },
			{ tag:'agoPagheClosed', icon:faLaptopSlash, label:'Chiusura AGO - Paghe' },
			{ tag:'agoAmministrazioneClosed', icon:faLaptopSlash, label:'Chiusura AGO - Amministrazione' },
		
			{ tag:'telematiciClosed', icon:faMobileSignal, label:'Disattivazione telematici' },
			{ tag:'ticClosed', icon:faLinkSlash, label:'Chusura TIC' },
			{ tag:'newsletterClosed', icon:faEnvelopesBulk, label:'Rimozione da newsletter' },
			{ tag:'newspaperClosed', icon:faNewspaper, label:'Rimozione da giornale' },
		
			// { tag:'commCreated', icon:faEnvelopeOpenText, label:'Preparazione lettera al cliente' },
			{ tag:'commSent', icon:faPaperPlane, label:'Invio lettera al cliente' },
			{ tag:'commSkipped', icon:faPaperPlane, icon2:faSlash, label:'Salta invio lettera al cliente' },
		
			{ tag:'docsReady', icon:faFileCheck, label:'Documenti preparati' },
			{ tag:'docsToHQ', icon:faFileCertificate, label:'Documenti depositati in sede' },
			{ tag:'docsDelivered', icon:faFileUser, label:'Documenti consegnati' },
		],
	},

	'comunicazione-morosi': {
		label: 'Comunicazione ai soci morosi',
		icon: iconCommMorosi,
		actions: {
			'create-comm-mora': { icon:faEnvelopeOpenText, label:'Prepara lettera messa in mora', customAction:'morosi-messa-in-mora' },
			'send-comm-mora': { icon:faPaperPlane, label:'Invio comunicazione messa in mora' },
			'success-comm-mora': { icon:faCheck, label:'Esito positivo dalla messa in mora' },
			'fail-comm-mora': { icon:faTimes, label:'Fallita la messa in mora' },

			'create-comm-diffida': { icon:faEnvelopeOpenText, label:'Prepara lettera di diffida', customAction:'morosi-diffida' },
			'send-comm-diffida': { icon:faPaperPlane, label:'Invio comunicazione di diffida' },
			'success-comm-diffida': { icon:faCheck, label:'Esito positivo dalla diffida' },
			'fail-comm-diffida': { icon:faTimes, label:'Fallita la diffida' },

			'create-comm-sospensione': { icon:faEnvelopeOpenText, label:'Prepara comunicazione di sospensione', customAction:'morosi-sospensione' },
			'send-comm-sospensione': { icon:faPaperPlane, label:'Invio comunicazione di sospensione' },
			'success-comm-sospensione': { icon:faCheck, label:'Esito positivo dalla comunicazione di sospensione' },
			'fail-comm-sospensione': { icon:faTimes, label:'Fallita comunicazione di sospensione' },

			'notify-zone': { icon:faCommentDots, label:'Notifica eseguita agli operatori di zona' },
			'mandate-revocation': { icon:faDiploma, icon2:faSlash, label:'Revoca mandato' },
			'suspend-tic': { icon:faLinkSlash, label:'Sospensione TIC' },
			'close-newsletter': { icon:faEnvelopesBulk, label:'Rimozione dalla newsletter' },

			'eval-fiscale': { icon:faBuildingColumns, label:'Calcolo quote fiscale' },
			'eval-paghe': { icon:faHandHoldingDollar, label:'Calcolo quote paghe' },
			'eval-caa': { icon:faCompassDrafting, label:'Calcolo quote CAA' },
			'close-ago-fiscale': { icon:faLaptopSlash, label:'Chiusura AGO - Fiscale' },
			'close-ago-paghe': { icon:faLaptopSlash, label:'Chiusura AGO - Paghe' },
			'close-ago-amministrazione': { icon:faLaptopSlash, label:'Chiusura AGO - Amministrazione' },

			'debt-collection-wait-done': { icon:faTimer, label:'Fine attesa prima del recupero crediti' },
			'debt-collection-start': { icon:faHandsHoldingDollar, label:'Inizio mandato recupero crediti' },
			'complete': { icon:faCheck, label:'Completa procedura' },
		},

		states: [
			// { tag:'commMoraCreated', icon:faEnvelopeOpenText, label:'Preparata lettera messa in mora' },
			{ tag:'commMoraSent', icon:faPaperPlane, label:'Inviata comunicazione messa in mora' },
			{ tag:'commMoraDone', icon:faHourglassClock, label:'Attesa esito messa in mora' },

			// { tag:'commDiffidaCreated', icon:faEnvelopeOpenText, label:'Preparata lettera di diffida' },
			{ tag:'commDiffidaSent', icon:faPaperPlane, label:'Inviata lettera di diffida' },
			{ tag:'commDiffidaDone', icon:faHourglassClock, label:'Attesa esito diffida' },

			// { tag:'commSospensioneCreated', icon:faEnvelopeOpenText, label:'Preparato preavviso di sospensione' },
			{ tag:'commSospensioneSent', icon:faPaperPlane, label:'Inviata comunicazione di sospensione' },
			{ tag:'commSospensioneDone', icon:faHourglassClock, label:'Attesa esito comunicazione di sospensione' },

			{ tag:'zoneNotified', icon:faCommentDots, label:'Notificata zona di competenza' },
			{ tag:'mandateRevoked', icon:faDiploma, icon2:faSlash, label:'Revoca mandato' },
			{ tag:'ticSuspended', icon:faLinkSlash, label:'Sospensione TIC' },
			{ tag:'newsletterClosed', icon:faEnvelopesBulk, label:'Chiusura newsletter' },

			{ tag:'quoteFiscaleEvaluated', icon:faBuildingColumns, label:'Calcolo quote fiscale' },
			{ tag:'quotePagheEvaluated', icon:faHandHoldingDollar, label:'Calcolo quote paghe' },
			{ tag:'quoteCAAEvaluated', icon:faCompassDrafting, label:'Calcolo quote CAA' },
			{ tag:'agoFiscaleClosed', icon:faLaptopSlash, label:'Chiusura AGO - Fiscale' },
			{ tag:'agoPagheClosed', icon:faLaptopSlash, label:'Chiusura AGO - Paghe' },
			{ tag:'agoAmministrazioneClosed', icon:faLaptopSlash, label:'Chiusura AGO - Amministrazione' },

			{ tag:'debtCollectionGrace', icon:faTimer, label:'Attesa prima del recupero crediti' },
			{ tag:'debtCollectionStarted', icon:faHandsHoldingDollar, label:'Mandato recupero crediti' },
		],
	},

	'espulsione': {
		label: 'Espulsione',
		icon: iconEspulsione,
		actions: {
			'upd-scheda-contabile': { icon:faMoneyCheckDollarPen, label:'Segnala nella scheda contabile' },
			'create-comm': { icon:faEnvelopeOpenText, label:'Prepara lettera per il cliente', customAction:'espulsione-comm' },
			'send-comm': { icon:faPaperPlane, label:'Invio comunicazione al cliente' },
			'close-tic': { icon:faLinkSlash, label:'Chiusura TIC' },
			'zone-ack': { icon:faCommentCheck, label:'Presa visione espulsione cliente' },
			'complete': { icon:faCheck, label:'Completa procedura' },
		},

		states: [
			{ tag:'updSchedaContabile', icon:faMoneyCheckDollarPen, label:'Segnalato nella scheda contabile' },
			// { tag:'commCreated', icon:faEnvelopeOpenText, label:'Preparata lettera per il cliente' },
			{ tag:'commSent', icon:faPaperPlane, label:'Inviata comunicazione al cliente' },
			{ tag:'ticClosed', icon:faLinkSlash, label:'Chiusura TIC' },
			{ tag:'zoneAck', icon:faCommentCheck, label:'Presa visione espulsione cliente' },
		],
	},
}

export const getActionDef = (type, action) => {
	if(commonActions.hasOwnProperty(action))
		return commonActions[action]
	else
		return workflowsDef[type]?.actions[action]
}

export const evalRequirements = (states, requirements) =>
	Object.entries(requirements).every(([ reqState, reqValue ]) => {
		const value = states?.[reqState]
		return (reqValue === true && value === true) ||
			(reqValue === false && value === false) ||
			(reqValue === 'defined' && value !== undefined)
	})

export const setupSymbols = [
	{ tag:'dismissFiscale', icon:faBuildingColumns, label:'Disattivazione fiscale' },
	{ tag:'dismissPaghe', icon:faHandHoldingDollar, label:'Disattivazione paghe' },
	{ tag:'dismissCAA', icon:faCompassDrafting, label:'Disattivazione CAA' },
	{ tag:'dismissFull', icon:faPersonToDoor, label:'Disattivazione totale' },
]
